@tailwind base;
@tailwind components;
@tailwind utilities;

html::-webkit-scrollbar {
  display: none;
}

* {
  -webkit-tap-highlight-color: transparent;
}

html {
  scrollbar-width: none;
}

.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dark-green {
  color: #2bb900;
}

.light-green {
  color: #3dff00;
}

.animate-replay {
  animation-name: replay-animation;
  animation-duration: 5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes replay-animation {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  90% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
  }
}

.animate-countdown {
  animation-name: countdown-animation;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes countdown-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#dog {
  transform-origin: 125px 200px;
  animation: run 1s infinite;
}

@keyframes run {
  0% {
    transform: rotate(0) translateX(0);
  }
  25% {
    transform: rotate(-20deg) translateX(5px);
  }
  50% {
    transform: rotate(0) translateX(10px);
  }
  75% {
    transform: rotate(20deg) translateX(5px);
  }
  100% {
    transform: rotate(0) translateX(0);
  }
}
