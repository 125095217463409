.content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.content-padding {
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
}
.noscroll {
  overflow: hidden;
}

@media screen and (max-width: 1000px) {
  .content-padding {
    position: relative;
    padding-top: 0px;
    display: block;
  }
}
