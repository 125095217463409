.view3d-container {
  position: relative;
  width: 100vw;
}

.popup-race-over {
  display: flex;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .race-over-text {
    color: #fff;
    font-size: 146px;
    text-shadow: 0px 0px 12px rgb(42 173 207 / 50%);
    align-self: center;
    margin-bottom: 2rem;
  }
  .race-over-content {
    color: #fff;
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 7px;
    text-transform: uppercase;
    align-self: center;
    .btn {
      background: linear-gradient(
        193.58deg,
        rgba(139, 224, 248, 0.863991) -63.31%,
        rgba(94, 173, 179, 0.0001) 252.27%
      );
      border: 1px solid #8be0f8;
      border-radius: 4px;
      padding: 16px 24px;
      letter-spacing: normal;
      text-decoration: none;
      color: #fff;
      margin: 0 2rem;
      display: inline-block;
    }
  }
}
.start-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  padding: 5px 10px;
  font-size: 30px;
  font-weight: bold;
  z-index: 2;
  color: white;
  cursor: pointer;
}
.ranking-board-container {
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 20;
  .ranking-item {
    position: absolute;
    display: flex;
    align-items: center;
    transform: translateY(-100%);
    transition: all linear 0.3s;
    .ranking-no {
      border-radius: 50%;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      &.first {
        background: yellow;
        color: black;
        border: 2px solid yellow;
      }
      &.second {
        background: #cfcfcf;
        color: black;
        border: 2px solid yellow;
      }
      &.third {
        background: #ec8632;
        color: black;
        border: 2px solid yellow;
      }
      &.rest {
        background: black;
        color: white;
        border: 2px solid white;
      }
    }
    .ranking-card {
      background-color: black;
      border: 2px solid rgb(0, 195, 0);
      margin-left: 12px;
      display: flex;
      height: 46px;

      .ranking-image {
        width: 95px;
        height: 42px;
        display: flex;
        align-items: center;
        .image-container {
          height: 46px;
          width: 65px;
          overflow: hidden;
          position: relative;
          top: 1px;
          img {
            vertical-align: bottom;
            height: 36px;
            object-fit: cover;
            transform-origin: 15% 5%;
            scale: 200%;
          }
        }

        &::after {
          content: "";
          border: solid green;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 14px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          position: relative;
          top: 3px;
          right: 5px;
        }
      }
      .ranking-name {
        position: relative;
        .name {
          color: white;
          width: 150px;
          font-weight: 600;
        }
        .type {
          font-weight: 600;
          color: grey;
          padding-top: 2px;
          width: 150px;
          overflow: hidden;
        }
        &::after {
          content: "";
          width: 80px;
          height: 1px;
          background-color: green;
          position: absolute;
          top: 50%;
          left: 0;
        }
      }
      .ranking-join-no {
        width: 40px;
        color: rgb(0, 195, 0);
        font-weight: 600;
        font-style: italic;
        font-size: 22px;
        display: inline-flex;
        padding: 4px;
        align-items: center;
      }
    }
  }
}
.auto-camara-container {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 3;
  display: flex;
  column-gap: 24px;
  justify-content: center;
  .auto-camara-label {
    font-size: 24px;
    color: white;
    line-height: 1.2;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #00ff00;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #00ff00;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.progress-bar-container {
  position: absolute;
  bottom: 40px;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  width: 500px;
}

.replay-cross-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  .replay-cross {
    animation: fly 3s linear 0.1s;
    -webkit-animation: fly 3s linear 0.1s;
    -moz-animation: fly 3s linear 0.1s;
    -ms-animation: fly 3s linear 0.1s;
    -o-animation: fly 3s linear 0.1s;
    pointer-events: none;
    top: 50%;
    left: 100%;
    position: absolute;
    z-index: 10;
    opacity: 0.9;
    transform: translate(100%, -50%);
    overflow: visible;
  }
}

@keyframes fly {
  0% {
    display: block;
    transform: translate(120%, -50%);
  }
  100% {
    transform: translate(-300%, -50%);
  }
}
