body {
  margin: 0;
  box-sizing: border-box;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

#app > div {
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
#root {
  height: 100vh;
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Audiowide;
  src: url(./components/assets/fonts/Audiowide-Regular.ttf);
  font-weight: 400;
}

#root {
  --main-green: #00b937e7;
  --content-padding: 100px;
}

.bar {
  position: relative;
}

.strike {
  position: absolute;
  width: 20px;
  height: 100%;
  background-color: #ffffff;
  opacity: 40%;
}

.strike:nth-of-type(1) {
  left: 85%;
}

.strike:nth-of-type(2) {
  left: 90%;
}

.strike:nth-of-type(3) {
  left: 95%;
}
